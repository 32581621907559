import { constants, Yup } from 'cng-web-lib'
import Namespace from '../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

const {
  locale: {
    key: { CommonValidationMessageKeys }
  }
} = constants

function makeValidationSchema(translate) {
  const requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )

  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexCharacters = '^[a-zA-Z]+$'
  let errMsgCharacters = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHABET
  )
  // const errMsgCharacters = 'Invalid Value - Allowed Alphabets'
  const regexPhoneNum = '^[0-9\\-\\+\\(\\)\\ ]+$'
  const errMsgPhoneNum =
    'Only Digits, Parenthesis, Hyphen, + are allowed'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    partyId: validateStringField(35, regexAlphaNumeric, errMsgAlphaNumeric),
    partyName: validateStringField(
      60,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ).required(requiredMessage),
    identificationCode: Yup.string().matches(regexAlphaNumeric,errMsgAlphaNumeric).max(15,"Max length allowed is 15").nullable()
      .when('partyType', {
        is: (value) => (value === 'BY' || value === 'VN'),
	  then: Yup.string().required(requiredMessage).matches(regexAlphaNumeric,errMsgAlphaNumeric).max(15,"Max length allowed is 15").nullable(),
	}).nullable(),
    fdaRegNum: validateStringField(15, regexAlphaNumeric, errMsgAlphaNumeric),
    address: validateStringField(
      110,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    ).required(requiredMessage),
    city: validateStringField(30, regexAlphaNumeric, errMsgAlphaNumeric),
    countryCode: Yup.string().nullable(),
    stateCode: Yup.string().nullable(),
    postalCode: validateStringField(9, regexAlphaNumeric, errMsgAlphaNumeric),
    contactName: validateStringField(60, regexAlphaNumeric, errMsgAlphaNumeric),
    // Temporary comment contact and fax validation to solved stopper issue in go live #412318 (update at least not allowed for special character #423380)
    contactTelphNum: validateStringField(35, regexAlphaNumeric, errMsgAlphaNumeric),
    contactFax: validateStringField(35, regexAlphaNumeric, errMsgAlphaNumeric),
    contactEmail: Yup.string()
      .nullable()
      .email('Invalid Email Id')
      .max(50, 'Max length allowed is 50'),
    partyType: Yup.string().nullable().required(requiredMessage),
    dba: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    partyidentifier: Yup.string().nullable()
	.when('partyType', {
	  is: (value) => (value === 'BY' || value === 'VN'),
	  then: Yup.string().required(requiredMessage).nullable(),
	}).nullable(),
    secondIdentificationCode: validateStringField(
      25,
      regexAlphaNumeric,
      errMsgAlphaNumeric
    )
  })
}

export default makeValidationSchema
