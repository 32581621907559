import { Yup } from 'cng-web-lib'
import moment from 'moment'
import Namespace from '../../../../constants/locale/Namespace'
import * as ValidationRegex from 'src/common/NBCIValidationRegex.js'
import NBCICanadaInvoiceKeys from 'src/constants/locale/key/NBCICanadaInvoice'

function makeValidationSchema(translate) {
  const regexAlphaNumeric =
    ValidationRegex.regexAlphaNumeric
  let errMsgAlphaNumeric = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR
  )
  // const errMsgAlphaNumeric =
  //   'Invalid Value - Allowed Alphanumeric, spaces, and /,+-$#@%&!^_:;.?()[]{}\'"'
  const regexPhoneNum = '^[0-9\\-\\+\\(\\)\\ ]+$'
  let errMsgPhoneNum = translate(
    Namespace.N_B_C_I_CANADA_INVOICE,
    NBCICanadaInvoiceKeys.Validations.ERR_MSG_ALPHA_NUMERIC_SPEC_CHAR_PHONE_FAX
  )
  // const errMsgPhoneNum =
  //   'Only Digits, Parenthesis, Hyphen, + are allowed'

  const validateStringField = (maxLength, regexPattern, errorMsg) =>
    Yup.string()
      .nullable()
      .matches(regexPattern, errorMsg)
      .max(
        maxLength,
        'Field can contain maximum of ' + maxLength + ' characters'
      )

  return Yup.object({
    blanketPeriodFrom: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    blanketPeriodTo: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    company: validateStringField(100, regexAlphaNumeric, errMsgAlphaNumeric),
    title: validateStringField(50, regexAlphaNumeric, errMsgAlphaNumeric),
    naftaDate: Yup.mixed()
      .nullable()
      .default(null)
      .test('is-date', 'Invalid date format', (value) => {
        if (value === null || value === '') {
          return true // Allow null or empty fields
        }
        return moment(value, 'YYYY-MM-DD', true).isValid()
      }),
    partyName: validateStringField(60, regexAlphaNumeric, errMsgAlphaNumeric),
    contactTelphNum: validateStringField(50, regexPhoneNum, errMsgPhoneNum),
    contactFax: validateStringField(50, regexPhoneNum, errMsgPhoneNum),
    email: Yup.string()
      .nullable()
      .email('Invalid Email Id')
      .max(35, 'Max length allowed is 35'),
    certifierType: Yup.string().nullable()
  })
}

export default makeValidationSchema
